<template>
    <div>
        <h4 class="credible_title">{{ title }}</h4>
    </div>
</template>

<script>
export default {
    name: '',
    props: {
        title: String,
        bottom:String,
    },
    created() { },
    mounted() { },
    data() {
        return {}
    },
    methods: {},
}
</script>

<style lang=scss scoped>
.credible_title {
    text-align: center;
    font-size: 2.2em;
    color: rgba(51, 51, 51, 1);
    padding-bottom: 4rem;
}
</style>